import { publicRuntimeConfig, serverRuntimeConfig } from "config";

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import MockAdapter from "axios-mock-adapter";

const mockApi = publicRuntimeConfig.MOCK_API ?? false;

export const mock = new MockAdapter(axios);

if (!mockApi) mock.restore();

const baseUrl = serverRuntimeConfig.API_BASE_URL;

const apiBaseUrl = `${baseUrl}`;

export abstract class AxiosAPI {
  protected client: AxiosInstance;
  protected token: string | undefined;

  constructor(token: string | undefined) {
    const config: AxiosRequestConfig = {
      baseURL: apiBaseUrl,
    };
    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }

    this.token = token;
    this.client = axios.create(config);
  }
}
